import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter) 
    {
        const response = await httpService.get(`invoices`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }
        
        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`invoices/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async approve(id) {
        const response = await httpService.get(`invoices/approve/${id}`);
        return response;
    },

    async reject(id, message) {
        const response = await httpService.post(`invoices/reject/${id}`, { id: 0, rejectReason: message });
        return response;
    },

    async createSO(id) {
        const response = await httpService.get(`invoices/createso/${id}`);
        return response;
    },
    
    default() {
        return {
            id: 0,
            vendorId: 0,
            vendorCode: null,
            vendorName: null,
            invoiceTypeId: 0,
            invoiceTypeCode: null,
            invoiceTypeDescription: null,
            vendorInvoiceNo: null,
            invoiceDate: null,
            dueDate: null,
            downloadVendorInvoice: null,
            submittedBy: null,
            submittedOn: null,
            reviewedBy: null,
            reviewedOn: null,
            invoiceStatusId: 0,
            invoiceStatusCode: null,
            invoiceStatusDescription: null,
            rejectReason: null,
            invoiceDetails: []
        };
    },

    _toObject(jsonData) {
        const result = this.default();

        result.id = jsonData.id;
        result.vendorId = jsonData.vendorId;
        result.vendorCode = jsonData.vendorCode;
        result.vendorName = jsonData.vendorName;
        result.invoiceTypeId = jsonData.invoiceTypeId;
        result.invoiceTypeCode = jsonData.invoiceTypeCode;
        result.invoiceTypeDescription = jsonData.invoiceTypeDescription;
        result.vendorInvoiceNo = jsonData.vendorInvoiceNo;
        result.invoiceDate = jsonData.invoiceDate;
        result.dueDate = jsonData.dueDate;
        result.downloadVendorInvoice = jsonData.downloadVendorInvoice;
        result.submittedBy = jsonData.submittedBy;
        result.submittedOn = jsonData.submittedOn;
        result.reviewedBy = jsonData.reviewedBy;
        result.reviewedOn = jsonData.reviewedOn;
        result.invoiceStatusId = jsonData.invoiceStatusId;
        result.invoiceStatusCode = jsonData.invoiceStatusCode;
        result.invoiceStatusDescription = jsonData.invoiceStatusDescription;
        result.invoiceDetails = jsonData.invoiceDetails;
        result.rejectReason = jsonData.rejectReason;
        
        return result;
    }
});