<template>
    <router-view></router-view>
    <h1>Review Invoice Vendor</h1>
    <hr />
    <bs-error-message :errors="errorMessages" />

    <div class="row justify-content-between">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-stripped">
            <thead>
                <tr>
                    <th>
                        <bs-ordering property="vendorInvoiceNo" @reorder="reorder">Invoice Number</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="invoiceType.code" @reorder="reorder">Invoice Type</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="invoiceDate" @reorder="reorder">Invoice Date</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="vendor.code" @reorder="reorder">Vendor Code</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="vendor.name" @reorder="reorder">Vendor Name</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="submittedOn" @reorder="reorder">Submitted Date</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="dueDate" @reorder="reorder">Due Date</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="invoiceStatus.code" @reorder="reorder">Invoice Status</bs-ordering>
                    </th>
                </tr>

                <tr>
                    <th>
                        <div>
                            <bs-textbox placeholder="Vendor Invoice No" v-model="filter.vendorInvoiceNoContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <div>
                            <bs-textbox placeholder="Invoice Type" v-model="filter.invoiceTypeDescriptionContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <bs-date-picker v-model="filter.invoiceDateFrom" placeholder="Invoice Date From" @change="search()" />
                        <bs-date-picker class="mt-1" v-model="filter.invoiceDateUntil" placeholder="Invoice Date To" @change="search()" />
                    </th>
                    <th>
                        <div>
                            <bs-textbox placeholder="Vendor Code" v-model="filter.vendorCodeContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <div>
                            <bs-textbox placeholder="Vendor Name" v-model="filter.vendorNameContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <bs-date-picker v-model="filter.submittedOnFrom" placeholder="Submitted Date From" @change="search()" />
                        <bs-date-picker class="mt-1" v-model="filter.submittedOnUntil" placeholder="Submitted Date To" @change="search()" />
                    </th>
                    <th>
                        <bs-date-picker v-model="filter.dueDateFrom" placeholder="Due Date From" @change="search()" />
                        <bs-date-picker class="mt-1" v-model="filter.dueDateUntil" placeholder="Due Date To" @change="search()" />
                    </th>
                    <th>
                        <div>
                            <bs-textbox placeholder="Invoice Status" v-model="filter.invoiceStatusDescriptionContains" @keyup="search()" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id">
                        <td>
                            <button @click="detailItem(item)" class="btn btn-link">
                                {{item.vendorInvoiceNo}}
                            </button>
                        </td>
                        <td>{{item.invoiceTypeDescription}}</td>
                        <td><view-date v-model="item.invoiceDate" /></td>
                        <td>{{item.vendorCode}}</td>
                        <td>{{item.vendorName}}</td>
                        <td><view-date-time v-model="item.submittedOn" /></td>
                        <td><view-date v-model="item.dueDate" /></td>
                        <td>{{item.invoiceStatusDescription}}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import ReviewInvoiceVendorService from "@/services/review-invoice-vendor-service.js";
import ViewDate from '../../../components/ViewDate.vue';
export default {
  components: { ViewDate },
    data: function () {
        return {
            filter: {
                invoiceStatusDescriptionContains: this.$route.query.invoiceStatusDescriptionContains,
                page: 1,
                perPage: 10,
                orderByProperty: 'submittedDate',
                orderType: 0
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array()
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/review-invoice-vendor") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async reorder(property, orderType) {
            this.filter.orderByProperty = property;
            this.filter.orderType = orderType;
            this.loadData();
        },
        detailItem(item) {
            this.$router.push(`/review-invoice-vendor/detail/${item.id}`);
        },
        async search() {
            this.filter.page = 1;
            this.loadData();
        },
        async downloadInvoice(item) {
            window.open(item.downloadVendorInvoice, '_blank');
        },
        async loadData() {
            const result = await ReviewInvoiceVendorService.getList(this.filter);
            if (result.status) {
                this.errorMessages = null; 
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            const data = result.data;
            this.pageNumber = data.pageNumber;
            this.perPage = data.perPage;
            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;
            this.data = data.data;
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>